<template>
  <b-card>
    <b-form>
      <b-form-group label="Tên chương trình tích điểm">
        <b-form-input
          ref="name"
          v-model="form.name"
          placeholder="Nhập tên chương trình"
          required
          autofocus
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Thời gian áp dụng chương trình">
        <b-form-checkbox
          v-model="form.is_specifically_time"
          :value="true"
          :unchecked-value="false"
          >Chọn thời hạn cụ thể</b-form-checkbox
        >
        <b-form-group class="mt-1">
          <div class="flex items-center gap-3">
            <b-form-datepicker
              ref="start_date"
              placeholder="Chọn ngày bắt đầu"
              v-model="form.from_date"
              :locale="$i18n.locale"
              v-bind="$getCalendarLabel($i18n.locale)"
            ></b-form-datepicker>
            <b-form-select
              ref="select_duaration"
              v-model="form.duration"
              :options="durationOptions"
              v-if="!form.is_specifically_time"
            ></b-form-select>
            <b-form-datepicker
              v-else
              placeholder="Chọn ngày kết thúc"
              ref="to_date"
              v-model="form.to_date"
              :locale="$i18n.locale"
              v-bind="$getCalendarLabel($i18n.locale)"
            ></b-form-datepicker>
          </div>
        </b-form-group>
      </b-form-group>

      <b-form-group label="Tỉ lệ tích điểm mua hàng">
        <div class="flex items-center gap-3">
          <b-input-group required>
            <template #append>
              <b-input-group-text>VND</b-input-group-text>
            </template>
            <b-form-input
              ref="moneyValue"
              v-model="form.moneyValue"
              @input="handleChangeMoneyValue"
            ></b-form-input>
          </b-input-group>
          <img src="/icons/icon-equivalent.svg" alt />
          <b-input-group required>
            <template #append>
              <b-input-group-text>Điểm</b-input-group-text>
            </template>
            <b-form-input
              ref="pointsValue"
              v-model="form.pointsValue"
              @input="handleChangePointValue"
            ></b-form-input>
          </b-input-group>
        </div>
      </b-form-group>
      <!-- <b-form-group>
        <b-form-checkbox
          v-model="form.is_programme"
          :value="true"
          :unchecked-value="false"
        >Chương trình áp dụng điều kiện</b-form-checkbox>
      </b-form-group>-->
      <!-- <div v-if="form.is_programme"> -->
      <!-- <div class="flex items-center gap-3 w-full">
          <b-form-group label="Tổng số điểm đã tích luỹ" class="w-100">
            <b-form-input v-model="form.total_point" placeholder="Nhập số điểm"></b-form-input>
          </b-form-group>
          <b-form-group label="Gói" class="w-100">
            <b-form-select v-model="form.member" :options="memberOptions"></b-form-select>
          </b-form-group>
      </div>-->
      <label style="font-size: 14px">Gói thành viên</label>
      <el-select
        v-model="form.member"
        multiple
        placeholder="Chọn HODO Membership"
        class="w-full mb-2"
      >
        <el-option
          v-for="item in HODO_MEMBERSHIP"
          :key="item.value"
          :label="item.text"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <!-- <b-form-group label="Gói thành viên" class="w-100">
        <b-form-select
          v-model="form.member"
          :options="HODO_MEMBERSHIP"
        ></b-form-select>
      </b-form-group> -->
      <b-form-group
        label="Sản phẩm, dịch vụ được áp dụng"
        v-slot="{ ariaDescribedby }"
      >
        <b-form-radio
          v-model="form.is_all_products"
          :aria-describedby="ariaDescribedby"
          :value="1"
          >Áp dụng cho tất cả sản phẩm, dịch vụ</b-form-radio
        >
        <b-form-radio
          v-model="form.is_all_products"
          :aria-describedby="ariaDescribedby"
          class="mt-1"
          :value="2"
          >Áp dụng cho sản phẩm, dịch vụ cụ thể</b-form-radio
        >
      </b-form-group>
      <div v-if="form.is_all_products === 2">
        <div class="flex justify-content-end">
          <b-button variant="primary" class="w-fit" @click="handleChooseProduct"
            >Chọn sản phẩm</b-button
          >
        </div>
        <b-table
          striped
          hover
          :items="dataTable"
          :fields="fieldsTable"
          class="mt-2 mb-3"
        >
          <template #cell(index)="data">{{ data.index + 1 }}</template>
        </b-table>
      </div>
      <!-- </div> -->
    </b-form>
    <div class="flex justify-content-end gap-3 mt-2">
      <b-button
        variant="outline-primary"
        @click="$router.push({ name: 'accumulate' })"
        >Huỷ</b-button
      >
      <b-button variant="primary" class="w-fit" @click="handleConfirm"
        >Lưu</b-button
      >
    </div>
    <ModalListMkProducts
      ref="ModalListMkProducts"
      @onChangeSelectedProducts="handleChangeSelectedProducts"
      @onSelectedAll="handleSelectedAllProducts"
    />
  </b-card>
</template>
<script>
import ModalListMkProducts from "../ModalListMkProducts.vue";
import appUtils from "@/utils/appUtils";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import { omit } from "lodash";
import {
  EXPIRED_RULE_CREDIT,
  HODO_CREDIT_TYPE,
  HODO_MEMBERSHIP,
} from "@/utils/constant";
import { Select, Option } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

export default {
  name: "CreateAccumulateTransaction",
  components: { ModalListMkProducts, "el-select": Select, "el-option": Option },
  data() {
    return {
      HODO_MEMBERSHIP,
      appUtils,
      form: {
        name: "",
        duration: 1,
        is_specifically_time: false,
        number_input: 1000,
        credit: 1,
        is_programme: false,
        total_point: "",
        member: [],
        is_all_products: 2,
        from_date: "",
        to_date: "",
        pointsValue: "",
        moneyValue: "",
      },
      memberOptions: [
        { value: "", text: "Chọn HODO Membership" },
        { value: 1, text: "HODO Silver" },
        { value: 2, text: "HODO Silver Plus" },
        { value: 3, text: "HODO Gold" },
      ],
      durationOptions: [
        { value: 1, text: "6 tháng kể từ ngày bắt đầu", month: 6 },
        { value: 2, text: "12 tháng kể từ ngày bắt đầu", month: 12 },
        { value: 3, text: "24 tháng kể từ ngày bắt đầu", month: 24 },
      ],
      fieldsTable: [
        {
          key: "index",
          label: "STT",
        },
        {
          key: "name",
          label: "Tên sản phẩm",
        },
        {
          key: "sku",
          label: "Mã đăng ký",
        },
        {
          key: "supplier.name",
          label: "Nhà cung cấp",
        },
      ],
      dataTable: [],
      realData: {
        moneyValue: null,
        pointsValue: null,
      },
    };
  },
  computed: {},
  mounted() {},
  methods: {
    handleChooseProduct() {
      this.$refs.ModalListMkProducts.handleOpenModal(true);
    },
    handleChangeSelectedProducts(data) {
      this.dataTable = data;
    },
    handleChangePointValue(value) {
      const newValue = Number(value?.toString()?.replaceAll(",", ""));
      this.form.pointsValue = appUtils.numberFormat(newValue);
      this.realData.pointsValue = newValue;
    },
    handleChangeMoneyValue(value) {
      const newValue = Number(value?.toString()?.replaceAll(",", ""));
      this.form.moneyValue = appUtils.numberFormat(newValue);

      this.realData.moneyValue = newValue;
    },
    async handleSave() {
      try {
        const userData = appUtils.getLocalUser();

        if (!userData.supplierInfo?.org_id) return;

        const params = {
          expired_rule: this.form.is_specifically_time
            ? EXPIRED_RULE_CREDIT.prefixReset
            : EXPIRED_RULE_CREDIT.loopReset,
          org_id: userData.supplierInfo?.org_id,
          name: this.form.name,
          type: HODO_CREDIT_TYPE.accumulate,
          start_date: this.form.from_date
            ? window.moment(this.form.from_date).valueOf()
            : undefined,
          expired_date: this.handleGetExpiredDate(),
          money_amount: this.realData.moneyValue,
          point: this.realData.pointsValue,
          is_apply_all_product: this.form.is_all_products === 1,
          membership_program_ids: this.form.member,
        };

        if (this.dataTable?.length && this.form.is_all_products === 2) {
          params.product_partner_program_ids = this.dataTable.map(
            (item) => item?.id
          );
        }

        const response = await this.$rf
          .getRequest("CommonRequest")
          .createPointProgram(params);

        if (response.status === 200) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t("Tạo chương trình tích điểm thành công"),
              icon: "CheckIcon",
              variant: "success",
            },
          });

          this.$router.push({ name: "accumulate" });
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleSelectedAllProducts(data) {
      this.dataTable = data;
    },
    handleGetExpiredDate() {
      if (this.form.is_specifically_time) {
        return this.form.to_date
          ? window.moment(this.form.to_date).valueOf()
          : undefined;
      } else {
        const month =
          this.durationOptions.find((item) => item.value === this.form.duration)
            ?.month || 0;

        return window.moment(this.form.from_date).add(month, "month").valueOf();
      }
    },
    handleConfirm() {
      const isValid = this.handleCheckValid();
      if (!isValid) return;

      this.$bvModal
        .msgBoxConfirm(
          "Bạn có chắc chắn muốn tạo chương trình tích điểm không?",
          {
            title: "Xác nhận tạo chương trình tích điểm",
            size: "sm",
            okVariant: "primary",
            okTitle: "Xác nhận",
            cancelTitle: "Huỷ",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (value) => {
          if (!value) return;
          this.handleSave();
        });
    },
    handleCheckValid() {
      if (!this.form.name) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t("Bạn cần điền tên chương trình tích điểm"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
        this.$refs.name.focus();
        return false;
      }
      if (
        !this.form.from_date ||
        (this.form.is_specifically_time &&
          (!this.form.to_date || !this.form.from_date)) ||
        (!this.form.is_specifically_time && !this.form.duration)
      ) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t("Bạn cần chọn thời gian áp dụng chương trình"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
        if (!this.form.from_date) {
          this.$refs.start_date.focus();
        } else if (this.form.is_specifically_time && !this.form.to_date) {
          this.$refs.to_date.focus();
        } else if (
          !this.form.is_specifically_time &&
          !this.form.select_duaration
        ) {
          this.$refs.select_duaration.focus();
        }

        return false;
      }

      if (!this.realData.pointsValue || !this.realData.moneyValue) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t("Bạn cần nhập đầy đủ thông tin tỷ lệ tích điểm"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
        this.$refs.moneyValue.focus();
        return false;
      }

      if (this.form.is_all_products === 2 && !this.dataTable?.length) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t(
              "Vui lòng chọn sản phẩm bạn muốn áp dụng chương trình"
            ),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
        return false;
      }

      if (!this.form.member?.length) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t("Bạn cần chọn gói thành viên"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });

        return false;
      }

      return true;
    },
  },
};
</script>
<style lang="scss" scoped>
.container-credit {
  background: #fff;
}
</style>